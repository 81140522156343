import YouTube from "../assets/projects/youtube.jpg";
import Decor from "../assets/projects/africaneye.jpg";
import JobPlus from "../assets/projects/jobplus.jpg";
import PhDV from "../assets/projects/phdv.jpg";
import Digital from "../assets/projects/digital.jpg";
import HairSprouts from "../assets/projects/hairsprouts.jpg";
import Portfolio from "../assets/projects/portfolio.jpg";
import Product from "../assets/projects/product.jpg";
import MovieHub from "../assets/projects/MovieHub.jpg";

export const data = [
  {
    id: 1,
    name: "YouTube Clone",
    image: YouTube,
    github: "https://github.com/ksencherey/youtube-clone",
    live: "https://ksencherey.github.io/youtube-clone/",
  },
  {
    id: 2,
    name: "African Eye Construction",
    image: Decor,
    github: "https://github.com/ksencherey/africaneye",
    live: "https://ksencherey.github.io/africaneye/",
  },
  {
    id: 3,
    name: "Job Search Engine",
    image: JobPlus,
    github: "https://github.com/ksencherey/jobplus",
    live: "https://ksencherey.github.io/jobplus/",
  },
  {
    id: 4,
    name: "PizzaHut Digital Ventures Clone",
    image: PhDV,
    github: "https://github.com/ksencherey/phdv-clone",
    live: "https://ksencherey.github.io/phdv-clone/",
  },
  {
    id: 5,
    name: "Digital Clock",
    image: Digital,
    github: "https://github.com/ksencherey/digitalclock",
    live: "https://ksencherey.github.io/digitalclock/",
  },
  {
    id: 6,
    name: "HairSprouts",
    image: HairSprouts,
    github: "https://accounts.shopify.com/",
    live: "https://hairsprouts.com/",
  },
  {
    id: 6,
    name: "Portfolio",
    image: Portfolio,
    github: "https://github.com/ksencherey/portfolio",
    live: "  https://ksencherey.github.io/portfolio/",
  },
  {
    id: 7,
    name: "Product Search Engine",
    image: Product,
    github: "https://github.com/ksencherey/productsearch",
    live: "  https://ksencherey.github.io/productsearch/",
  },
  {
    id: 7,
    name: "Movie Search Engine",
    image: MovieHub,
    github: "https://github.com/ksencherey/moviehub",
    live: "  https://ksencherey.github.io/moviehub/",
  },
];
